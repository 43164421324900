import {
  CreateBookmarkResponse,
  DeleteBookmarkResponse,
  ListBookmarksResponse,
} from '@wix/ambassador-events-schedule-v1-schedule-item/types'
import {addQueryParams} from '@wix/panda-js-utils'
import {ExtendedListScheduleItemsResponse, SchedulePageData} from '@wix/wix-events-commons-statics'
import type {ControllerParams} from '@wix/yoshi-flow-editor'
import {Api as BaseApi} from '../../../../commons/utils/api'

export const createApi = (controllerParams: ControllerParams) => {
  const {api, writeApi, logError} = new BaseApi(controllerParams)

  return {
    getScheduleData: async (params: {
      slug: string
      draftPreviewToken: string
      timeZoneId: string
      language: string
      locale: string
      isEditor: boolean
    }) => api.get<SchedulePageData>(addQueryParams(`/html/schedule-data`, params)).catch(logError),
    getSchedule: (
      eventId: string,
      location: string,
      tags: string[],
      locale: string,
      draft: boolean = false,
    ): Promise<ExtendedListScheduleItemsResponse> => {
      return api
        .get(
          addQueryParams(`/web/events/${eventId}/schedule-items`, {
            stageName: location ? [location] : undefined,
            tag: tags.length ? tags : undefined,
            locale,
            draft: draft ? 'true' : 'false',
          }),
        )
        .catch(logError) as Promise<ExtendedListScheduleItemsResponse>
    },
    getUserProfileImage: (userId: string) =>
      api.get<{imageUrl: string}>(`/web/user/${userId}/profileImage`).catch(logError),
    getBookmarks: (eventId: string): Promise<ListBookmarksResponse> =>
      api.get(`/web/events/${eventId}/bookmarks`).catch(logError),
    createBookmark: (eventId: string, itemId: string): Promise<CreateBookmarkResponse> =>
      writeApi.post(`/web/events/${eventId}/bookmarks/${itemId}`, {}).catch(logError),
    deleteBookmark: (eventId: string, itemId: string): Promise<DeleteBookmarkResponse> =>
      api.delete(`/web/events/${eventId}/bookmarks/${itemId}`).catch(logError),
  }
}

export type Api = ReturnType<typeof createApi>
